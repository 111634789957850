import {
  Content,
  FolderGroup,
  NotificationActionType,
  SecurityType,
  SensitivityLevel,
  SharingGroup,
  SharingGroupRule,
  UserSummary,
} from '.';
import { EventLocationCategoryType } from './event.domain';

export interface LayerLegend {
  content?: Content;
  name: string;
  position: { x: number; y: number };
  size: { x: number; y: number };
}

export interface LayerCustomFields {
  [key: string]: string;
}
export interface Layer {
  _id: string;
  Name: string;
  Icon?: string;
  Description?: string;
  Color?: string;
  URL?: string;
  Source?: string;
  SourceOWSLayer?: string;
  ProxyWMS?: boolean;
  SourceType?: LayerSourceType;
  Provider?: string[];
  ImageURL?: string;
  ImageBoundingBox?: number[][];
  CustomFields?: LayerCustomFields;
  LayerType: LayerType;
  InformationSource: LayerType;
  Security: SecurityType;
  SensitivityLevel: SensitivityLevel;
  CreatedAt?: string;
  UpdatedAt: string;
  CreatedBy?: UserSummary;
  IsDefault?: boolean;
  LightMSADefault: string[];
  SmartRules?: { [key: string]: any | Criteria }[];
  SharingGroups: SharingGroup[];
  SharingGroupRules: SharingGroupRule[];
  Owner?: string;
  Tags?: string[];
  Layers?: string[];
  LogicalQuerySmartLayers?: string;
  DefaultLayers?: string[];
  DefaultFromSituation?: string;
  DefaultPresentation?: {
    Active: boolean;
    Icon: string;
    Color: string;
    AutoSelectOnMap?: boolean;
  };
  ShareWithOutsideYA: boolean;
  FavoritedByGroupNames?: string[];
  Folders?: FolderGroup[];
  _changes?: {
    MSAObjects_ids: {
      added: string[];
      removed: string[];
    };
  };
  ZoneLayer?: boolean;
  Content?: Content;
  GroupLayer?: boolean;
  LayerParents?: string[];
  GroupChildrenLayers?: Layer[];
  Order?: number;
  ZeeIdentifier?: string;
  InternalWMS?: boolean;
  Schema?: any;
  GeoServerStyle?: { Definition: any; sld: string };
  DuplicatedFrom?: string;
  MSAObjects_ids: string[];
  Clusters: string[];
  ToggleSharing?: boolean;
  Virtual?: boolean;
  Legends?: LayerLegend[];
}

export type HumanMSAObjectActionType = 'Created' | 'Updated' | 'Deleted' | 'AddToLayer' | 'RemoveFromLayer' | 'Undo';

export interface CreateMSAObjectDTO {
  Layer_id: string;
  Geometry: { Type: string; Coordinates: any[]; Center?: number[]; Radius?: number };
  Icon?: string;
  Color?: string;
  Opacity?: number;
  LineOpacity?: number;
  LineWidth?: number;
  LineColor?: string;
  LineDashed?: boolean;
  FillImage?: string;
  FillImageOpacity?: number;
  Properties?: { [key: string]: string | any };
  Human?: { [key: string]: string };
  Geofencing?: string;
  Incident?: Incident;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  ExtendedData?: ExtendedData;
  ExtendedDataOptions?: ExtendedDataOptions;
  Event?: any;
  WantToCorrelate?: boolean;
}

export interface Geometry {
  Type: string;
  Coordinates: any[];
  Center: number[];
  Radius: number;
}

export interface MSAObject {
  _id: string;
  Name: string;
  Color?: string;
  Icon?: string;
  CreatedAt: string;
  UpdatedAt: string;
  updatedAt: string;
  Layer_id: string;
  ReferenceGeometry: any;
  SensitivityLevel?: SensitivityLevel;
  Geometry: any;
  Properties?: { [key: string]: string | any };
  CustomFields?: { [key: string]: string };
  Human?: { [key: string]: string };
  Prediction?: boolean;
  AttachedObjects?: string[];
  LineColor?: string;
  LineWidth?: number;
  Opacity?: number;
  LineOpacity?: number;
  FillImage?: string;
  FillImageOpacity?: number;
  MSAObject_id?: string;
  LineDashed?: boolean;
  Geofencing: string;
  Incident?: IncidentWarningOld; //TODO: Remove after Migration
  HumanActionType?: HumanMSAObjectActionType;
  HumanActionContext?: any;
  NeedUpdateExtendedData?: boolean;
  Type?: string;
  ExtendedData: ExtendedData;
  ExtendedDataOptions: ExtendedDataOptions;
  Timestamp?: Date;
  isTrespasser?: boolean;
  geometryFileURL?: string;
  FromReplay?: string;
  Provider?: MsaObjectProvider;
  ExpireAt?: string;
}

export enum MsaObjectProvider {
  AIS = 'AIS',
  SKYLIGHT = 'SKYLIGHT',
  HUMAN = 'HUMAN',
}

export enum VesselConfigType {
  Global = 'Global',
  Center = 'Center',
  Situation = 'Situation',
  User = 'User',
}

export interface ExtendedDataOptions {
  ShowInMap?: {
    GeneralSection?: VesselConfigType;
    ClassificationSection?: VesselConfigType;
    AttachedObjectsSection?: VesselConfigType;
  };
}

export type VesselExtendedData = {
  GeneralSection: { [field: string]: any };
  ClassificationSection: { [field: string]: any };
  AttachedObjectsSection: { [field: string]: any };
};

export interface ExtendedData {
  Global?: VesselExtendedData;
  Center?: { Local: VesselExtendedData };
  Situation?: VesselExtendedData;
  User?: VesselExtendedData;
}

export interface SkylightObject {
  _id: string;
  Geometry: GeometryType;
  type: SkylightPropertiesType;
  Properties: SkylightPropertiesType;
  Icon: string;
  ExternalId: string;
  ExternalUpdatedAt: Date;
  Type: SkylightLayerType;
  Layer_id: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CpaObject {
  _id: string;
  TrackObjects: MSAObjectMap[];
  Owner: string;
  ActivedFor: {
    User: string;
    Situation: string;
  }[];
  RefreshTimeInSec: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
}

export type CpaObjectProcessedResult = {
  cpaObject: CpaObject;
  cpaPoints: number[][];
  durationInHours: number;
  time: Date;
  processedAt: Date;
  distance: number;
  actualTracksPositions: number[][];
  center: number[];
};

export interface MapObjectFilter {
  _id: string;
  User: string;
  Name: string;
  Rules: {}[];
  ShortcutRules: {}[];
  LogicalQuery: string;
  ShortcutLogicalQuery: string;
  BetweenShortcutAndRules: string;
  CreatedAt?: Date;
  UpdatedAt?: Date;
}

export interface SkylightPropertiesType {
  transshipment?: string;
  participants?: string[];
  start_point?: number[];
  end_point?: number[];
  start_time?: Date;
  end_time?: Date;
  dist2coast?: number;
  fishing_score?: number;
  fishing_model_name?: string;
}

export enum SkylightLayerType {
  DRV = 'DarkRendezVous',
  SRV = 'StandartRendezVous',
  FISHING = 'Fishing',
  detection = 'detection',
  speed_range = 'speed_range',
  aoi_visit = 'aoi_visit',
  dark_activity = 'dark_activity',
  viirs = 'viirs',
  sar_sentinel1 = 'sar_sentinel1',
  eo_sentinel2 = 'eo_sentinel2',
}

export interface Vessel {
  createdAt?: Date;
  updatedAt?: Date;
  Name: string;
  MMSI: number;
  Country: string;
  VesselType: string;
  IMO: number;
  Length: number;
  Width: number;
  SkylightExternalId?: string;
  RadarHubExternalId?: string;
}

export interface IncidentWarningOld {
  //TODO: Remove after Migration
  WarningMessage: string;
  IncidentType: string;
  Description: string;
  Status: string;
  Tags?: string[];
  CreatedAt?: string;
  UpdatedAt?: string;
  Situation_id: string;
}
export interface Incident {
  _id: string;
  layerId: string;
  situationId: string;
  geometry: GeometryType;
  properties: PropertiesType;
  createdAt: string;
  updatedAt: string;
}

export interface GeometryType {
  Type: string;
  Coordinates: string[];
  Center: number[];
  Radius: number;
}

export interface PropertiesType {
  Name: string;
  WarningMessage: string;
  IncidentType: string;
  Description: string;
  EventCategory: EventLocationCategoryType;
  Status: IncidentStatus;
  Tags: string[];
  Custom: Record<string, string>;
}

export enum IncidentStatus {
  Created = 'Created',
  Validated = 'Validated',
  Updated = 'Updated',
  Terminated = 'Terminated',
}

export enum MSAObjectProperties {
  UUID = 'UUID',
  Name = 'Name',
  Provider = 'Provider',
  Owner = 'Owner',
  ReceptionUtc = 'ReceptionUtc',
  PointID = 'PointID',
  PositionUtc = 'PositionUtc',
  MMSI = 'MMSI',
  CallSign = 'CallSign',
  IMONumber = 'IMONumber',
  ShipType = 'ShipType',
  COG = 'COG',
  FusedCourse = 'Fused Course',
  Heading = 'Heading',
  SOG = 'SOG',
  FusedSpeed = 'Fused Speed',
  Speed = 'Speed',
  Length = 'Length',
  Width = 'Width',
  Destination = 'Destination',
  DestinationETA = 'DestinationETA',
  NavigationalStatus = 'NavigationalStatus',
  Flag = 'Flag',
  AisEntityType = 'AisEntityType',
  Layer = 'Layer',
}

export enum TrackSymbology {
  'Friendly',
  'Suspect',
  'Neutral',
  'Under Evaluation',
  'Hostile',
}

export enum Criteria {
  $ne = '$ne',
  $lt = '$lt',
  $gt = '$gt',
  $eq = '$eq',
  $gte = '$gte',
  $lte = '$lte',
}

export enum SearchCriteria {
  $eq = 'EQ',
  $ne = 'NE',
  $lt = 'LT',
  $lte = 'LTE',
  $gt = 'GT',
  $gte = 'GTE',
  $in = 'IN',
  $out = 'OUT',
  $insi = 'INSI',
}

export type MSAObjectMap = Omit<MSAObject, 'Layer_id'> & { layerIds: string[] };
export type MSAObjectMapReplay = MSAObjectMap & { OriginalLayerIds: string[] };

export enum LayerProperties {
  Name = 'Name',
  Tags = 'Tags',
  Security = 'Level',
  SensitivityLevel = 'Sensitivity',
  Description = 'Description',
}

export interface MSAObjectHistory {
  _id: string;
  UpdatedAt: string;
  Properties?: { [key: string]: string };
  Geometry?: any;
  MSAObject_id: string;
  Color?: string;
  Icon?: string;
  Timestamp?: Date;
}

export interface CpaObjectNotification {
  ActionType: NotificationActionType;
  ObjectInfo: CpaObject;
}

export enum LayerType {
  View = 'View',
  Information = 'Information',
  Smart = 'Smart',
  File = 'File',
  Image = 'Image',
  Points = 'Points',
  ThirdPartyLayer = 'ThirdPartyLayer',
  Remote = 'Remote',
  Event = 'Event',
  Dynamic = 'Dynamic',
}

export enum LayerSourceType {
  WMS = 'WMS',
  SKYLIGHT = 'Skylight',
  EVENT_LOCATION = 'EventLocation',
  Charts = 'Charts',
  NOAA = 'NOAA',
  Copernicus = 'Corpnnicus',
  Vessels = 'Vessels',
  EEZ = 'Exclusive_Economic_Zones_(EEZ)',
  Territorial_Seas = 'Territorial_Seas_(12NM)',
  Contigouos_Seas = 'Contigoous_Seas_(24NM)',
  Internal_Waters = 'Internal_Waters',
  Archipelagic_Waters = 'Archipelagic_Waters',
  Joint_Regime_Area = 'Joint_Regime_Area',
  Ports = 'Ports',
  VesselsOfInterest = 'VesselsOfInterest',
}

export interface MSAMapConfig {
  VesselNameDisplay: string[];
}

export enum AttributionType {
  skylight = 'skylight',
  mapbox = 'mapbox',
  openstreetmap = 'openstreetmap',
}
export enum DynamicModes {
  Translation = 'Translation',
  FurtherOnCircle = 'FurtherOnCircle',
}

export enum MapConfigViewType {
  Default = 'Default',
}

export interface LayerConfig {
  LayerId: string;
  IsSelected: boolean;
  IsHidden: boolean;
  Opacity: number;
}

export interface MapLocation {
  Type: string;
  Coordinates: number[];
  Zoom: number;
}

export interface UserMapConfig {
  SituationId: string;
  UserId: string;
  Layers: LayerConfig[];
  ViewType: MapConfigViewType;
  MapLocation: MapLocation;
  CreatedAt?: string;
  UpdatedAt?: string;
  AppType: string;
}

export interface Property {
  Type: 'Human' | 'System';
  Value: string;
  Visible: boolean;
  _id: string;
}
export interface MsaObjectDefaultProperties {
  _id?: string;
  Value: string;
  Type?: string;
  Visible?: boolean;
  Description?: string;
  Locked?: boolean;
}
export interface MsaObjectDefaultPropertiesFlag extends MsaObjectDefaultProperties {
  Translations: {
    English: string;
    Portuguese: string;
    French: string;
    Spanish: string;
  };
}

export interface MsaDefaultMessage {
  _id?: string;
  Name: string;
  Visible: boolean;
  Description: string;
}
