import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Domain from '../../domain';
import { api_default_limit } from '../api.constants';

export interface CreateContent {
  Sensitivity: Domain.SensitivityLevel;
  Security: Domain.SecurityType;
  Source: Domain.ContentSource;
  ParentName?: string;
  ParentId?: string;
  Message?: string;
  Identifier?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ContentApiService {
  constructor(private http: HttpClient) {}

  private readonly content_api_path = '/api/content';

  downloadAttachment(filename: string, pathtostorage?: string) {
    const params: any = { filename };
    if (pathtostorage) {
      params.pathtostorage = pathtostorage;
    }
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.get<any>(`${this.content_api_path}/download`, { headers, params, responseType: 'blob' as 'json' });
  }

  listContentsFromSituation({
    situation,
    searchTerm,
    limit = api_default_limit,
    offset = 0,
  }: {
    situation: Domain.Situation;
    searchTerm?: string;
  } & Domain.IPaginatedRequest) {
    let params = new HttpParams()
      .set('situationId', situation._id)
      .set('limit', String(limit))
      .set('offset', String(offset));

    if (searchTerm) params = params.set('searchTerm', searchTerm);

    return this.http.get<Domain.RequestResponse>(`${this.content_api_path}/`, { params: params });
  }

  listReports({ searchTerm }: { searchTerm?: string } & Domain.IPaginatedRequest) {
    let params = new HttpParams();
    if (searchTerm) params = params.set('searchTerm', searchTerm);
    return this.http.get<Domain.RequestResponse>(`${this.content_api_path}/getreports`, { params: params });
  }

  getContentByFilename(filename: string) {
    return this.http.get<Domain.Content>(`${this.content_api_path}/filename/${filename}`);
  }

  uploadAttachment(file: File, createContent: CreateContent) {
    const formData = new FormData();
    formData.append('file', file);

    let headers = new HttpHeaders({
      Sensitivity: String(createContent.Sensitivity),
      Security: String(createContent.Security),
      Source: String(createContent.Source),
      ParentName: String(createContent.ParentName || ''),
      ParentId: String(createContent.ParentId || null),
      Identifier: String(createContent.Identifier || null),
    });

    if (createContent?.Message) headers = headers.set('Message', createContent.Message);

    return this.http.post<Domain.Content>(`${this.content_api_path}/`, formData, { headers: headers });
  }

  uploadFile(file: File, type: 'converter' | 'image' | 'general' | 'icon') {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('option', type);
    return this.http.post<any>(`/light/api/upload`, formData);
  }
}
